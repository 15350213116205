import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faYoutube, faSnapchatGhost } from '@fortawesome/free-brands-svg-icons'

import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/styles.css'
import '../../styles/contact.css'

const Contact = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="flex-row contact-row">
        <div className="equal">
          <h2>Location</h2>
          <address><FontAwesomeIcon icon={faMapMarkerAlt} /> <span>{data.markdownRemark.frontmatter.addressLine1}<br />
            {data.markdownRemark.frontmatter.addressLine2 ? (
              <span>{data.markdownRemark.frontmatter.addressLine2}<br /></span>
            ) : null }
            {data.markdownRemark.frontmatter.city}, {data.markdownRemark.frontmatter.state} {data.markdownRemark.frontmatter.zip}</span></address>
          <h2>Give Us A Call</h2>
          <FontAwesomeIcon icon={faPhone} /> <a href={`tel:+1${data.markdownRemark.frontmatter.telephone}`}>{formatPhoneNumber(data.markdownRemark.frontmatter.telephone.toString())}</a>
          <h2>Send Us An Email</h2>
          <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${data.markdownRemark.frontmatter.email}`}>{data.markdownRemark.frontmatter.email}</a>
        </div>
        <iframe title="MS Votes location on a map." src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3372.2061776904598!2d-90.18110415272686!3d32.30631417081641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8628325694e18d67%3A0x95d76c0c984f6d02!2s730%20N%20Jefferson%20St%20%2C%20Jackson%2C%20MS%2039202!5e0!3m2!1sen!2sus!4v1594843273672!5m2!1sen!2sus" width="600" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
      <h2>Social</h2>
      <div className="social-row">
        {data.markdownRemark.frontmatter.socialMedia.map(social => (
          <a href={social.link} className="social-media-link">
            {social.platform === 'Twitter' || social.link.includes('twitter') ? <FontAwesomeIcon icon={faTwitter} /> : null}
            {social.platform === 'Facebook' || social.link.includes('facebook') ? <FontAwesomeIcon icon={faFacebook} /> : null}
            {social.platform === 'Instagram' || social.link.includes('instagram') ? <FontAwesomeIcon icon={faInstagram} /> : null}
            {social.platform === 'Youtube' || social.link.includes('youtube') ? <FontAwesomeIcon icon={faYoutube} /> : null}
            {social.platform === 'Snapchat' || social.link.includes('snapchat') ? <FontAwesomeIcon icon={faSnapchatGhost} /> : null}
            {social.handle}
          </a>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query ContactQuery {
  markdownRemark(frontmatter: {title: {eq: "Contact"}}) {
    frontmatter {
      addressLine1
      addressLine2
      city
      state
      zip
      email
      title
      telephone
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      socialMedia {
        platform
        handle
        link
      }
    }
  }
}
`

export default Contact