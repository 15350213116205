import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './Header.module.css'

const Header = (props) => {
  const image = getImage(props.background)
  return (
    <div className={styles.image_container}>
      <GatsbyImage image={image} alt={props.alt} />
      <div className={styles.text_container}>
        <h1 className={styles.header_text}>{props.children}</h1>
      </div>
    </div>
  )
}

export default Header